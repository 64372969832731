<div class="op-modal--portal">
  <div class="op-modal--modal-container job-status--modal"
       tabindex="0">
    <div class="op-modal--modal-header">
      <h3 [textContent]="title"></h3>
      <a class="op-modal--modal-close-button">
        <i
          class="icon-close"
          (click)="closeMe($event)"
          [attr.title]="text.closePopup">
        </i>
      </a>
    </div>

    <div>
      <div class="loading-indicator--location"
           data-indicator-name="modal">
        <div class="status-icon-wrapper" *ngIf="!isLoading && statusIcon">
          <span [ngClass]="statusIcon" class="icon-big"></span>
        </div>
      </div>
      <div>
        <span [textContent]="message"></span>
        <span [hidden]="!downloadHref">
          {{ text.download_starts }}
          <a #downloadLink
             download
             [textContent]="text.click_to_download"
             [attr.href]="downloadHref">
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
